import { IS_CLIENT } from "~/config/app.config";

type TLoadingOptions = {
  skip?: boolean;
};

/**
 * Composable with utils to control loading state based on active data fetching queries.
 * `start` should be called before starting a query, `end` should be called after the query is finished.
 * Works only on client side. Can be skipped by passing `skip: true` in options.
 */
export const useQueryLoadingState = (options: TLoadingOptions = {}) => {
  const activeDataFetchCount = useState("activeDataFetchCount", () => 0);

  const shouldControlLoading = IS_CLIENT && !options.skip;

  // increment active data fetch count to show loading state if it’s the first running request
  const start = () => {
    if (shouldControlLoading) {
      activeDataFetchCount.value++;
    }
  };

  // decrease active data fetch count to hide loading state if it’s the last running request
  const end = () => {
    if (shouldControlLoading) {
      // add some delay to wait for next request to start if there is any in the cascade
      setTimeout(() => {
        activeDataFetchCount.value--;
      }, 250);
    }
  };

  return { start, end };
};
