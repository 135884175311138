import { AsyncDataRequestStatus } from "#app";
import { QueryOptions } from "@apollo/client";

import { useDataCache } from "~/composables/useDataCache";

type TOptions = {
  /** hash of the query call used to identify cached entry */
  hash: string;
  /** parse query name, used for logging */
  queryName: string;
  /** query options that will be used on refetch */
  queryOptions: QueryOptions;
};

type TQueryResult = PromisedType<ReturnType<typeof useAsyncQuery>>;
type TQueryResultExtended = TQueryResult & {
  forceRefresh: TQueryResult["refresh"];
};

/**
 * returns a function to obtain returns a useAsyncQuery-like object with data taken from the cache
 */
export const useCachedQueryResult = () => {
  const cache = useDataCache();
  const { clients } = useApollo();

  /**
   * returns a useAsyncQuery-like object with data taken from the cache
   * assumes it’s called only after the cache was checked for existence of the entry
   */
  const getResult = ({
    hash,
    queryName,
    queryOptions,
  }: TOptions): TQueryResultExtended => {
    const logCacheAction = getLogCacheActionForKey(queryName);
    const cachedEntry = cache.get(hash);

    const data = toRef(cachedEntry.data);
    const error = ref(null);
    const status = ref<AsyncDataRequestStatus>("success");
    const pending = computed(() => status.value === "pending");

    const clear = () => {
      data.value = null;
      error.value = null;
      cache.remove(hash);
      status.value = "idle";
    };

    // fetches the data once and stores the result in the cache
    // ! does not update the state – only updates the cache for the next read
    const fetchAndCache = async () => {
      if (!clients) return;

      try {
        const updatedData = await clients.default.query(queryOptions);

        cache.set(hash, updatedData.data);
        logCacheAction("cache refreshed");

        return updatedData;
      } catch (error) {
        // ? how to set error value here?
        return null;
      }
    };

    // useAsyncQuery native refetch like function to refresh the data from the server
    // handles the status and state update
    const forceRefresh = async () => {
      if (!clients) return;

      status.value = "pending";
      logCacheAction("refetching");

      const updatedData = await fetchAndCache();

      if (!updatedData) {
        status.value = "error";
        data.value = null;
        // ? how to set error value here?
        return;
      }

      data.value = updatedData.data;
      error.value = null;
      status.value = "success";
    };

    // refresh that uses the cache if possible
    const refresh = async () => {
      const cachedEntry = cache.get(hash);

      if (cachedEntry.data && !cachedEntry.shouldRefresh) {
        logCacheAction("refetch skipped");
        return;
      }

      return await forceRefresh();
    };

    // if the cache is old-ish, run refresh in the background while the previous data is still returned
    // to have fresh data on the next read
    if (cachedEntry.shouldRefresh) {
      logCacheAction("cache refreshing");
      fetchAndCache();
    }

    // return useAsyncQuery-like result
    return {
      data,
      error,
      clear,
      status,
      pending,
      refresh,
      forceRefresh,
      execute: refresh,
    };
  };

  return getResult;
};
