import { MAIN_MOVIE_GROUP_ID } from "~/utils/constants";

export const useGtmLayer = () => {
  const gtm = useGtm();
  const enabled = ref(true);

  const state = useState("gtm", () => ({
    title: "",
    trackId: "",
  }));

  const trackPageView = (title) => {
    if (!gtm || !enabled.value) return;
    const requestURL = useRequestURL();

    gtm.trackEvent({
      event: "virtual_pageview",
      virtual_pageview_url: requestURL.pathname,
      virtual_pageview_title: title,
    });
  };

  const trackMovieSearch = (searchQuery = {}) => {
    if (!gtm || !enabled.value) return;
    const defaultSearchQuery = {
      genre: "",
      search_keyword: "",
      number_results: 0,
      _clear: true,
    };

    const query = { ...defaultSearchQuery, ...searchQuery };

    gtm.trackEvent({
      event: "movie_search",
      ...query,
    });
  };

  const trackItemListView = ({ title, trackId, cinemaSlug }, items = []) => {
    if (!gtm || !enabled.value) return;

    _trackEcommerceEvent("view_item_list", items, {
      title,
      trackId,
      cinemaSlug,
    });
  };

  const trackItemDetailClick = ({ title, trackId, cinemaSlug }, items = []) => {
    if (!gtm || !enabled.value) return;

    _clearState();

    state.value.title = title;
    state.value.trackId = trackId;

    _trackEcommerceEvent("select_item", items, { title, trackId, cinemaSlug });
  };

  const trackItemDetailView = (cinemaSlug, items) => {
    if (!gtm || !enabled.value) return;

    _trackEcommerceEvent("view_item", items, {
      title: state.value.title,
      trackId: state.value.trackId,
      cinemaSlug,
    });

    _clearState();
  };

  const trackPromotionEvent = (
    event,
    { creativeName, promotionId, promotionName },
  ) => {
    if (!gtm || !enabled.value) return;

    gtm.trackEvent({ ecommerce: null });

    gtm.trackEvent({
      event,
      ecommerce: {
        creative_name: creativeName,
        promotion_id: promotionId,
        promotion_name: promotionName,
      },
    });
  };

  const trackCtaButtonEvent = ({ location, type, url, text }) => {
    if (!gtm || !enabled.value) return;

    gtm.trackEvent({
      event: "cta_button",
      location,
      type,
      url,
      text,
      _clear: true,
    });
  };

  const trackUser = (id) => {
    if (!gtm || !enabled.value) return;

    const type = id ? "logged in" : "not logged in";

    gtm.trackEvent({
      user: {
        type,
        id,
      },
      _clear: true,
    });
  };

  const _trackEcommerceEvent = (
    eventName,
    items,
    { title, trackId, cinemaSlug },
  ) => {
    // Clear the previous ecommerce object
    gtm.trackEvent({ ecommerce: null });

    const transformedItems = _transformData(items, {
      title,
      trackId,
      cinemaSlug,
    });

    gtm.trackEvent({
      event: eventName,
      ecommerce: {
        item_list_id: trackId,
        item_list_name: title,
        items: transformedItems,
      },
    });
  };

  const _clearState = () => {
    state.value.title = "";
    state.value.trackId = "";
  };

  const _transformData = (data, { title, trackId, cinemaSlug }) => {
    return data.map((item) => {
      return {
        affiliation: "Cinestar",
        coupon: "",
        currency: "",
        item_list_name: title,
        item_list_id: trackId,
        item_id:
          item?.EventId ??
          (item?.MovieGroupId === MAIN_MOVIE_GROUP_ID
            ? item?.MovieId
            : item?.MovieGroupId),
        item_name: item.TitleArray,
        item_brand: cinemaSlug,
        item_category: "",
        item_category_2: "Film",
        discount: "",
        price: "",
        value: "",
        quantity: "",
      };
    });
  };

  return {
    trackPageView,
    trackMovieSearch,
    trackUser,
    trackItemListView,
    trackItemDetailClick,
    trackItemDetailView,
    trackPromotionEvent,
    trackCtaButtonEvent,
  };
};
