import { useFeatureFlag } from "~/composables/useFeatureFlag";
import { IS_CLIENT } from "~/config/app.config";

type TCacheEntry = {
  value: unknown;
  validUntil: number;
  revalidateAfter: number;
};

type TSetOptions = {
  validity?: number;
  revalidateAfter?: number;
};

type TGetResult<TValue> =
  | { data: undefined; shouldRefresh?: false }
  | { data: TValue; shouldRefresh: boolean };

type TKey = string;

const MINUTE = 1000 * 60;
// time after which cached data is no longer used
const DEFAULT_VALIDITY = MINUTE * 5;
// time after which cached data is still used, but get refreshed with background refetch
const DEFAULT_REVALIDATE = MINUTE * 3;

const PERSISTENCE_KEY = "data-cache";
const LOG_LEVEL: TLogLevel = "none" as TLogLevel; // * set to "all" for debugging

let cache = new Map<TKey, TCacheEntry>();

/**
 * A composable that provides a simple in-memory cache with persistence.
 */
export const useDataCache = () => {
  const FF_CACHE_ENABLED = useFeatureFlag("dataCacheEnabled");
  const canUseCache = FF_CACHE_ENABLED && IS_CLIENT;

  // * SINGLE RECORD OPERATIONS

  // stores a value in cache and updates persisted copy
  // if the cache is full, it clears the cache
  const set = <TValue>(key: TKey, value: TValue, options?: TSetOptions) => {
    if (!canUseCache) return;

    const now = Date.now();
    const validity = options?.validity ?? DEFAULT_VALIDITY;
    const revalidateAfter = options?.revalidateAfter ?? DEFAULT_REVALIDATE;

    const cacheEntry: TCacheEntry = {
      value,
      validUntil: now + validity,
      revalidateAfter: now + revalidateAfter,
    };

    try {
      cache.set(key, cacheEntry);
      localStorage.setItem(PERSISTENCE_KEY, serializeCache(cache));
    } catch (error) {
      logCacheAction("overflown");
      clear();
    }
  };

  // retrieves a value from in-memory cache
  const get = <TValue>(key: TKey): TGetResult<TValue> => {
    if (!canUseCache) return { data: undefined };

    const cachedEntry = cache.get(key);

    if (!cachedEntry) {
      return { data: undefined };
    }

    const now = Date.now();
    const refreshTimestamp = cachedEntry.revalidateAfter;
    const shouldRefresh = refreshTimestamp < now;

    return { data: cachedEntry.value as TValue, shouldRefresh };
  };

  // checks if a value is in in-memory cache and if it's still valid
  const has = (key: TKey) => {
    if (!canUseCache) return false;

    const cachedEntry = cache.get(key);

    if (!cachedEntry) {
      return false;
    }

    const now = Date.now();
    const validityTimestamp = cachedEntry.validUntil;
    const isExpired = validityTimestamp < now;

    if (isExpired) {
      remove(key);
      return false;
    }

    return true;
  };

  // removes a value from in-memory cache and updates persisted copy
  const remove = (key: TKey) => {
    if (!canUseCache) return;

    cache.delete(key);
    localStorage.setItem(PERSISTENCE_KEY, serializeCache(cache));
  };

  // * GLOBAL OPERATIONS

  // initializes the in-memory cache from persistence
  const init = () => {
    if (!canUseCache) return;

    const persistedCache = localStorage.getItem(PERSISTENCE_KEY);

    if (persistedCache) {
      cache = deserializeCache(persistedCache);
      logCacheAction("restored");
    }
  };

  // clears both the in-memory cache and its persisted counterpart
  const clear = () => {
    if (!canUseCache) return;

    localStorage.removeItem(PERSISTENCE_KEY);
    cache.clear();
    logCacheAction("cleared");
  };

  // clear cache if the page was reloaded
  // should work for `ctrl/cmd + r`, `F5`, browser reload button, ...
  const clearOnReload = () => {
    const isSupported =
      typeof performance !== "undefined" &&
      typeof performance.getEntriesByType === "function";

    if (!canUseCache) return;
    if (!isSupported) return;

    // https://developer.mozilla.org/en-US/docs/Web/API/Performance/getEntriesByType
    // https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming/type
    const entries = performance
      .getEntriesByType("navigation")
      .filter((entry) => entry instanceof PerformanceNavigationTiming);

    const didReload = entries.some((entry) => entry.type === "reload");

    if (didReload) {
      clear();
    }
  };

  // clear cache on reload button press
  const clearOnKeypress = () => {
    if (!canUseCache) return;

    const checkKeysAndClear = (event: KeyboardEvent) => {
      const isRKey = event.key === "r";
      const hasModifier = event.ctrlKey || event.metaKey;
      const isF5 = event.key === "F5";

      if ((isRKey && hasModifier) || isF5) {
        clear();
      }
    };

    onMounted(() => {
      document.addEventListener("keydown", checkKeysAndClear);
    });

    onBeforeMount(() => {
      document.removeEventListener("keydown", checkKeysAndClear);
    });
  };

  return {
    set,
    get,
    has,
    remove,

    init,
    clearOnReload,
    clearOnKeypress,
  };
};

// * UTILS

type TLogLevel = "all" | "none" | "cache" | "fetch";

type TCacheAction =
  | "stored"
  | "removed"
  | "cleared"
  | "restored"
  | "overflown"
  | "fetching"
  | "refetching"
  | "refetch skipped"
  | "cache hit"
  | "cache refreshing"
  | "cache refreshed";

const isLoggedAction = (action: TCacheAction) => {
  if (LOG_LEVEL === "all") return true;
  if (LOG_LEVEL === "none") return false;

  switch (action) {
    case "stored":
    case "removed":
    case "cleared":
    case "restored":
    case "overflown":
    case "cache hit":
    case "refetch skipped":
    case "cache refreshed":
      return LOG_LEVEL === "cache";

    case "fetching":
    case "refetching":
    case "cache refreshing":
      return LOG_LEVEL === "fetch";

    default:
      return false;
  }
};

const getLogStyles = (action: TCacheAction) => {
  switch (action) {
    case "stored":
    case "restored":
    case "cache hit":
    case "refetch skipped":
      return "color: #2ecc71";

    case "removed":
    case "cleared":
    case "overflown":
    case "cache refreshed":
      return "color: #e67e22";

    case "fetching":
    case "refetching":
    case "cache refreshing":
      return "color: #e74c3c";

    default:
      return "";
  }
};

const logCacheAction = (action: TCacheAction) => {
  getLogCacheActionForKey("common")(action);
};

const IS_LOG_ENABLED = IS_CLIENT && import.meta.dev;

export const getLogCacheActionForKey =
  (key: string) => (action: TCacheAction) => {
    if (!IS_LOG_ENABLED || !isLoggedAction(action)) return;

    console.log(`${key} -> %c${action}`, getLogStyles(action));
  };

const serializeCache = (cacheInstance: typeof cache) => {
  return JSON.stringify(Array.from(cacheInstance.entries()));
};

const deserializeCache = (persistedCache: string) => {
  return new Map(JSON.parse(persistedCache)) as typeof cache;
};
