import { isValidCinemaLang, isValidCinemaSlug } from "~/utils/constants";

/**
 * returns a function to obtain current homepage path that can be used as a link
 */
export const useGetHomepagePath = () => {
  const route = useRoute();

  const getHomepagePath = () => {
    const isRouteLangValid = isValidCinemaLang(route.params.lang);
    const isRouteCinemaSlugValid = isValidCinemaSlug(route.params.cinemaslug);

    return isRouteLangValid && isRouteCinemaSlugValid
      ? `/${route.params.lang}/${route.params.cinemaslug}`
      : "/";
  };

  return getHomepagePath;
};
