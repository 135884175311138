import { DocumentNode } from "graphql";

type TOptions = {
  query: DocumentNode;
  headers: Record<string, string>;
  variables: Record<string, string>;
};

/**
 * returns a unique hash based on the query, headers and variables
 */
export const getDataRequestHash = (options: TOptions) => {
  const queryName = parseQueryName(options.query);
  const query = queryName ?? options.query.loc?.source.body ?? "";

  const variables = normalizeHash(options.variables);
  const headers = normalizeHash(options.headers);

  return JSON.stringify({ headers, query, variables });
};

// * UTILS

// parses the query name from the request body
export const parseQueryName = (query: DocumentNode) => {
  const requestBody = query.loc?.source.body ?? "";
  // matches stings like "query QueryName" and returns "QueryName"
  // ! make sure to use single query per file and use unique query names
  const matches = requestBody.match(/query\s*(\w+)/);

  if (!matches) return null;
  if (matches.length < 2) return null;

  return matches[1];
};

// normalizes the record hash by sorting keys and values
// to ensure matching despite different order
const normalizeHash = (data: Record<string, string>) => {
  // sort array-like values
  const sortedValues = Object.entries(data).map(([key, value]) => {
    const sortedValue = Array.isArray(value) ? value.sort() : value;
    return `${key}:${sortedValue}`;
  });

  // sort by keys
  return sortedValues.sort().join(",");
};
