const COMPONENT_VISIBILITY_TYPES = {
  all: "all",
  unLogged: "unLogged",
  logged: "logged",
  membersCsc: "member",
};

const LOGIN_PROVIDERS = {
  facebook: "FACEBOOK",
  google: "GOOGLE",
  apple: "APPLE",
};

const COMPONENT_VISIBILITY_ENUM = {
  show: "show",
  hide: "hide",
  default: "default",
};

const DEFAULT_CINEMA_HANDLER = "hradec";

const CINEMA_WILDCARD_HANDLER = "default";

const MOVIE_DETAIL_PROGRAMME_ANCHOR = "#program";

const DEFAULT_CINEMA_LANGUAGE = "cz";

const CINEMA_LANGUAGES = {
  cz: "cz",
  // en: "en",
};

const CINEMA_HANDLERS = {
  boleslav: "boleslav",
  budejovice: "budejovice",
  budejoviceIgy: "budejovice-igy",
  hradec: "hradec",
  jihlava: "jihlava",
  liberec: "liberec",
  olomouc: "olomouc",
  opava: "opava",
  ostrava: "ostrava",
  pardubice: "pardubice",
  plzen: "plzen",
  praha5: "praha5",
  praha9: "praha9",
};

const SCREENING_TYPES = {
  screening: "screening",
  upcoming: "upcoming",
};

const SCREENING_TYPES_GTM = {
  [SCREENING_TYPES.screening]: "promitame_a_predprodej",
  [SCREENING_TYPES.upcoming]: "brzy_uvedeme",
};

const PROMOTION_NAMES_GTM = {
  weekMovie: "Film týdne",
  campaign: "Akce",
};

const CTA_TYPES_GTM = {
  registration: "registration",
  login: "login",
  cinestarClub: "cinestar_club",
};

const MAIN_MOVIE_GROUP_ID = "-2147483648";

const API_ERRORS = {
  E_API_ALREADY_AUTHORIZED: "E_API_ALREADY_AUTHORIZED",
};

const isValidCinemaSlug = (cinemaSlug) => {
  return Object.values(CINEMA_HANDLERS).includes(cinemaSlug);
};

const isValidCinemaLang = (cinemaSlug) => {
  return Object.values(CINEMA_LANGUAGES).includes(cinemaSlug);
};

const isValidCinemaPath = (cinemaLang, cinemaSlug) => {
  return isValidCinemaLang(cinemaLang) && isValidCinemaSlug(cinemaSlug);
};

const HEADERS_NAMES = {
  authToken: "Token",
};

export {
  HEADERS_NAMES,
  COMPONENT_VISIBILITY_TYPES,
  LOGIN_PROVIDERS,
  COMPONENT_VISIBILITY_ENUM,
  DEFAULT_CINEMA_HANDLER,
  CINEMA_WILDCARD_HANDLER,
  MOVIE_DETAIL_PROGRAMME_ANCHOR,
  DEFAULT_CINEMA_LANGUAGE,
  CINEMA_LANGUAGES,
  MAIN_MOVIE_GROUP_ID,
  API_ERRORS,
  SCREENING_TYPES,
  SCREENING_TYPES_GTM,
  PROMOTION_NAMES_GTM,
  CTA_TYPES_GTM,
  isValidCinemaSlug,
  isValidCinemaLang,
  isValidCinemaPath,
};
