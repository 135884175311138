// should be ideally taken from env variable and changed based on the target environment
const DEV_APP_HOSTNAME = "https://cinestar.digital.cz";
const LOCALHOST = "http://localhost:3000";

/**
 * replaces current hostname with provided `replacement` string
 * if run on localhost, it replaces testing hostname instead
 */
export const replaceHostname = (url: string, replacement: string) => {
  if (!location) return url;

  const normalizedUrl = replaceLocalhost(url);
  const hostname = replaceLocalhost(location.origin);
  const hostnameRegex = new RegExp(`^${hostname}`);

  return normalizedUrl.replace(hostnameRegex, replacement);
};

/**
 * converts testing hostname to localhost in dev mode
 * so in-app links work properly
 */
export const convertToLocalhostInDev = (url: string) => {
  if (!import.meta.dev) return url;
  return url.replace(DEV_APP_HOSTNAME, LOCALHOST);
};

// * UTILS

const replaceLocalhost = (url: string) => {
  return url.replace(LOCALHOST, DEV_APP_HOSTNAME);
};
