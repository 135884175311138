import { onMounted, nextTick } from "#imports";
import { useSeo } from "~/composables/useSeo";
import { useFetchHomepageData } from "~/composables/useFetchCraftData";
import { useGtmLayer } from "~/composables/useGtmLayer";

export const useHomepage = async () => {
  const previewToken = useState("previewToken", () => null);
  const cinemaPageData = useState("cinemaPageData", () => null);
  const globalPageData = useState("globalPageData", () => null);
  const cinemaPageSeo = useState("cinemaPageSeo", () => null);
  const globalPageSeo = useState("globalPageSeo", () => null);

  const gtmLayer = useGtmLayer();

  onMounted(async () => {
    if (previewToken.value !== null) {
      await refresh();
      await nextTick(() => {
        setPageData();
      });
    }

    gtmLayer.trackPageView(pageSeo.value?.seoTitle || "Homepage");
  });

  const { data, refresh } = await useFetchHomepageData();

  const setPageData = () => {
    cinemaPageData.value = data.value?.homepage;
    cinemaPageSeo.value = data.value?.homepageSeo;
    globalPageData.value = data.value?.defaultHomepage;
    globalPageSeo.value = data.value?.defaultHomepageSeo;
  };

  setPageData();

  const { getSeoMetaData } = useSeo();

  if (data.value?.homepageSeo) {
    useHead(getSeoMetaData(data.value.homepageSeo));
  } else if (data.value?.defaultHomepageSeo) {
    useHead(getSeoMetaData(data.value.defaultHomepageSeo));
  }

  const items = computed(() =>
    data.value?.homepage?.componentsBuilder?.length
      ? data.value.homepage.componentsBuilder
      : data.value?.defaultHomepage?.componentsBuilder,
  );

  const pageSeo = computed(() => {
    if (data.value?.homepageSeo) {
      return data.value.homepageSeo;
    }
    return data.value?.defaultHomepageSeo || null;
  });

  return {
    components: items,
    pageSeo,
  };
};
