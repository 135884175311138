<script setup lang="ts">
import { computed, getSiteHandle, onMounted, useGtmLayer } from "#imports";

import { useFetchCinemaScreeningsWithMoviesData } from "~/composables/useFetchCraftData";
import { MAIN_MOVIE_GROUP_ID, SCREENING_TYPES_GTM } from "~/utils/constants";

import AppMovieSwiper from "~/components/AppMovieSwiper.vue";
import {
  sortOverrideMoviesByOrderAndAlphabetically,
  transformOverrideMoviesData,
  setUniqueCustomIndexOrder,
  implementMoviesWithOverriedOrder,
} from "~/utils/movies";

const props = defineProps({
  header: {
    type: String,
    default: "",
  },
  moviesOrderOverride: {
    type: Array,
    default: () => [],
  },
  cinemaHallType: {
    type: Object,
    default: null,
  },
  cinemaHallTags: {
    type: Array,
    default: () => [],
  },
});

const gtmLayer = useGtmLayer();
const route = useRoute();

const startOfToday = new Date().setHours(0, 0, 0, 0);

const buildQuery = () => {
  const query = {
    site: getSiteHandle(),
    Start: `>=${new Date(startOfToday).toISOString()}`,
  };
  const tags = [];
  if (props.cinemaHallType) {
    tags.push(props.cinemaHallType.id);
  }
  if (props.cinemaHallTags.length > 0) {
    props.cinemaHallTags.forEach((tag) => {
      tags.push(tag.id);
    });
  }

  if (tags.length > 0) {
    query["tags"] = tags;
  }

  return query;
};

const {
  data: localScreenings,
  refresh: refreshLocalScreenings,
  pending,
} = await useFetchCinemaScreeningsWithMoviesData(buildQuery());

let unique = (a: [], t: any = {}) => a.filter((e) => !(t[e] = e in t));

const movieOrderOverrides = computed(() => {
  return setUniqueCustomIndexOrder(
    transformOverrideMoviesData(props.moviesOrderOverride).sort(
      sortOverrideMoviesByOrderAndAlphabetically,
    ),
  );
});

const purchaseAbleMovies = computed<string[]>(() => {
  const ids = unique(
    localScreenings?.value?.scheduledEventsEntries?.map?.(
      (screening: { [key: string]: any }) => screening.TitleId,
    ) ?? [],
  );
  localScreenings?.value?.scheduledEventsEntries?.forEach?.((screening) => {
    if (
      ids.includes(screening?.movieInCinemasAvailability?.[0]?.MovieId) &&
      !ids.includes(screening?.movieInCinemasAvailability?.[0]?.MovieGroupId)
    ) {
      ids.push(screening.movieInCinemasAvailability[0].MovieGroupId);
    }
  });
  return ids;
});

const movies = computed(() => {
  const movies = {};
  const screeningsProperties = {};

  const alreadyShownMainMovies: string[] = [];

  localScreenings?.value?.scheduledEventsEntries?.forEach((screening) => {
    const id =
      screening.movieInCinemasAvailability?.[0]?.MovieGroupId !==
      MAIN_MOVIE_GROUP_ID
        ? screening.movieInCinemasAvailability?.[0]?.MovieGroupId
        : screening.movieInCinemasAvailability?.[0]?.MovieId;

    if (screening.Properties) {
      screening.Properties.forEach((property) => {
        if (!screeningsProperties[id]) {
          screeningsProperties[id] = {};
        }
        screeningsProperties[id][property.Code] = property.Name;
      });
    }

    if (purchaseAbleMovies.value.includes(id) && !(id in movies)) {
      const movie = screening.movieInCinemasAvailability[0];
      movies[id] = {
        ...movie,
        Properties: {},
      };
    }
  });

  Object.entries(screeningsProperties).forEach(([id, properties]) => {
    if (id in movies) {
      movies[id].Properties = properties;
    }
  });

  let resMovies = Object.values(movies).filter(
    (movie) =>
      !alreadyShownMainMovies.includes(
        movie?.movieGroup?.[0]?.MovieId || movie?.MovieId,
      ) &&
      alreadyShownMainMovies.push(
        movie?.movieGroup?.[0]?.MovieId || movie?.MovieId,
      ) &&
      true,
  );

  if (resMovies.length === 0) {
    return [...Array(7)].map(() => ({ placeholder: true }));
  }

  const alreadyPremieredMovieIndex = resMovies.findIndex((movie) =>
    movie?.Premiere ? new Date(movie?.Premiere) <= new Date() : false,
  );

  resMovies = [
    ...resMovies.slice(0, alreadyPremieredMovieIndex).reverse(),
    ...resMovies.slice(alreadyPremieredMovieIndex),
  ];

  return implementMoviesWithOverriedOrder(resMovies, movieOrderOverrides.value);
});

const isMoviePremiering = (movie) => {
  return localScreenings?.value?.scheduledEventsEntries?.some(
    (screening) =>
      [movie?.MovieId, movie?.movie?.groupId].includes(screening?.TitleId) &&
      screening?.Properties?.some?.((prop) => Number(prop?.Code) === 8),
  );
};

const hasMovies = computed(() => {
  return (
    (!pending.value && movies.value.every((movie) => movie.placeholder)) ===
    false
  );
});

onMounted(async () => {
  await refreshLocalScreenings();

  gtmLayer.trackItemListView(
    {
      title: props.header,
      trackId: SCREENING_TYPES_GTM.screening,
      cinemaSlug: route.params?.cinemaslug ?? DEFAULT_CINEMA_HANDLER,
    },
    movies.value,
  );
});
</script>

<template>
  <AppMovieSwiper
    v-if="hasMovies"
    :header="header"
    :track-id="SCREENING_TYPES_GTM.screening"
    :movies="movies"
    is-purchase-able
    :is-movie-premiering="isMoviePremiering"
  />
</template>
