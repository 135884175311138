import { getSiteHandle, useAsyncQuery } from "#imports";
import { QueryOptions } from "@apollo/client";
import { DocumentNode } from "graphql";

import { useCachedQueryResult } from "~/composables/useCachedQueryResult";
import { useQueryLoadingState } from "~/composables/useQueryLoading";

const CRAFT_SEGMENT_TOKENS = {
  GLOBALS: "EkHFCQMLh8B5xiGQNxRrU2D3wYUzUIdi",
  NAVIGATIONS: "8lsFbMA7ALGv5grad5tXJpFdR3I4HA4K", // TODO
  HOMEPAGE: "pi0KwK7kWw6Sy4SlJ7UQaroPvzAT1BaI",
  OTHER_PAGES: "6J1TrNB0pWbb_ZBLnQyGaDkdE356E3Z7",
  ASYNC_COMPONENTS: "xNzScua-E3LE3kGRyYKr0MGxGASM6mwX",
  CINEMA_SCREENINGS: "jDwfZ1hXWbrzYPkc7ZAb21RJOPGKvaIn", // TODO
  SPECIFIC_MOVIES: "fVI-cPmlx0isZQQupccDwEMf0Q-QMrKB", // TODO
  CINEMA_HALS: "gZ6_62gIDqVrXqQ-CFsbFsA77ttj-qY0", // TODO
  CINEMA_MOVIE_DETAIL_COMPONENTS: "ADySJps2Of9VzV_45VDgogH0y8rCdjJU",
  MAIN_MOVIES: "TXtN95FT7GTRFLp9oYwlm7wHSXccRNvo",
};

type THeaders = Record<string, string>;

// this should actually be derived from useAsyncQuery but I’m not sure how to use the right version of it since it has multiple overloads
type TQueryOptions = OmitSafe<QueryOptions, "query"> & {
  cache?: boolean;
};

type TSetDataCache = ReturnType<typeof useDataCache>["set"];
type TDataCacheOptions = Parameters<TSetDataCache>[2] & {
  skip?: boolean;
};

type TLoadingStateOptions = FirstParam<typeof useQueryLoadingState>;

type TGetCachedQueryResult = ReturnType<typeof useCachedQueryResult>;
type TQueryResultExtended = ReturnType<TGetCachedQueryResult>;

// TODO maybe refactor the interface to accept just a query and then a single object with all option groups?

/**
 * Runs specified GQL query providing common variables.
 * Handles data caching and loading state control (both can be configured).
 */
const useFetchCraftClient = async (
  query: DocumentNode,
  headers: THeaders = {},
  options: TQueryOptions = {},
  cacheOptions: TDataCacheOptions = {},
  loadingStateOptions: TLoadingStateOptions = {},
): Promise<TQueryResultExtended> => {
  const route = useRoute();
  const cache = useDataCache();
  const getCachedQueryResult = useCachedQueryResult();
  const queryLoading = useQueryLoadingState(loadingStateOptions);

  const DEFAULT_OPTIONS: TQueryOptions = {
    cache: false,
    context: { headers },
    variables: {
      site: getSiteHandle(),
      defaultSite: `default${route.params?.lang === "en" ? "En" : ""}`,
    },
  };

  const queryOptions = { query, ...DEFAULT_OPTIONS, ...options };
  const variables = queryOptions.variables || {};
  const shouldUseCache = !cacheOptions.skip && cacheOptions.validity !== 0;

  const hash = getDataRequestHash({ query, headers, variables });
  const queryName = parseQueryName(query) ?? "unknown";
  const logCacheAction = getLogCacheActionForKey(queryName);

  // on client, try to get data from cache
  if (shouldUseCache && cache.has(hash)) {
    logCacheAction("cache hit");

    return getCachedQueryResult({ hash, queryName, queryOptions });
  }

  // nothing in the cache or cache not available -> fetch data

  logCacheAction("fetching");

  queryLoading.start();
  const result = await useAsyncQuery(queryOptions);
  queryLoading.end();

  // cache data for future use
  if (shouldUseCache && result.data.value) {
    cache.set(hash, result.data.value, cacheOptions);
  }

  // force refresh that always fetches the data from the server and updates the cache
  const forceRefresh = async () => {
    logCacheAction("refetching");
    await result.refresh();
    cache.set(hash, result.data.value, cacheOptions);
  };

  // refresh that uses the cache if possible
  const refresh = async () => {
    if (shouldUseCache) {
      const cachedEntry = cache.get(hash);

      if (cachedEntry.data && !cachedEntry.shouldRefresh) {
        logCacheAction("refetch skipped");
        return;
      }
    }

    return await forceRefresh();
  };

  // rewrite the refresh method to use the cache if possible and return original refresh method as `forceRefresh`
  // ! the `refresh` method may work differently than expected – use `forceRefresh` to get the native behavior
  return { ...result, forceRefresh, refresh, execute: refresh };
};

// * COMPOSABLES FOR SPECIFIC SCHEMAS

const useGlobalsSchemaQuery = (query, variables = {}) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.GLOBALS}`,
    },
    { variables },
  );
};

const useNavigationsSchemaQuery = (query, variables = {}) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.NAVIGATIONS}`,
    },
    { variables },
  );
};

const useHomepageSchemaQuery = (query, options = {}) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.HOMEPAGE}`,
    },
    options,
  );
};

const useOtherPageSchemaQuery = (query, variables = {}) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.OTHER_PAGES}`,
    },
    { variables },
  );
};

const useAsyncComponentsSchemaQuery = (query, options = {}) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.ASYNC_COMPONENTS}`,
    },
    options,
  );
};

const useCinemaScreeningsSchemaQuery = (query, variables) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.CINEMA_SCREENINGS}`,
    },
    { variables },
  );
};

const useSpecificMoviesSchemaQuery = (query, options) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.SPECIFIC_MOVIES}`,
    },
    options,
  );
};

const useSpecificMovieComponentsSchemaQuery = (query, variables) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.CINEMA_MOVIE_DETAIL_COMPONENTS}`,
    },
    { variables },
  );
};

const useCinemaHallsSchemaQuery = (query, variables = {}) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.CINEMA_HALS}`,
    },
    { variables },
  );
};

const useGroupedSubMoviesSchemaQuery = (query, variables = {}) => {
  return useFetchCraftClient(
    query,
    {
      Authorization: `Bearer ${CRAFT_SEGMENT_TOKENS.MAIN_MOVIES}`,
    },
    { variables },
  );
};

export {
  useGlobalsSchemaQuery,
  useNavigationsSchemaQuery,
  useHomepageSchemaQuery,
  useOtherPageSchemaQuery,
  useAsyncComponentsSchemaQuery,
  useCinemaScreeningsSchemaQuery,
  useSpecificMoviesSchemaQuery,
  useSpecificMovieComponentsSchemaQuery,
  useCinemaHallsSchemaQuery,
  useGroupedSubMoviesSchemaQuery,
};
