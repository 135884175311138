type TFeatureFlag = keyof ReturnType<
  typeof useRuntimeConfig
>["public"]["flags"];

/**
 * returns the value of a feature flag
 * this value is static and is evaluated only once when the composable is initialized
 */
export const useFeatureFlag = (flag: TFeatureFlag) => {
  const runtimeConfig = useRuntimeConfig();

  return runtimeConfig.public.flags[flag];
};
